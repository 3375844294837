module.exports = [
  {
    "source": "start",
    "sourceHandle": null,
    "target": "end",
    "targetHandle": null,
    "type": "buttonedge",
    "markerEnd": { "type": 'arrowclosed'},
    "label": null,
    "id": "reactflow__edge-1null-2null",
    "animated": true,
    "style": { "stroke": 'blue' },
  }
]