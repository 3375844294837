module.exports = [
  {
    "id": "start",
    "type": "input",
    "data": {
        "label": "Start"
    },
    "position": {
        "x": 250,
        "y": 25
        },
    "selected": false,
},
{
    "id": "end",
    "type": "output",
    "data": {
        "label": "End"
    },
    "position": {
        "x": 250,
        "y": 250
    },
    "selected": false,
}
];